import TicketSalesConversionReportChart from "./ui/chart";
import TicketSalesConversionReportTable from "./ui/table";
import { useEffect, useState } from "react";
import { getReportTicketConversion } from "../../../../service/report/ticket";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";
import SuperAdminPassword from "../../../../components/SuperAdminPassword";

const TicketSalesConversion = () => {
    const [ticketConversionData, setTicketConversionData] = useState<Array<ITicketConversionData>>([]);
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);

    useEffect(() => {
        requestReportTicketConversion()
    }, [])

    const requestReportTicketConversion = () => {
        getReportTicketConversion()
            .then((res) => setTicketConversionData(res.data))
            .catch(error => console.error('error', error))
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        {ticketConversionData.length > 0 && <>
            <TicketSalesConversionReportChart data={ticketConversionData} />
            <TicketSalesConversionReportTable data={ticketConversionData} />
        </>}
    </>
}

export default TicketSalesConversion;