import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getKakaoUserAdmin } from '../../../service/login';
import { Button } from 'antd';

const KakaoLoginCallback = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    useEffect(() => {
        if (!code) {
            navigate('/kakaologin');
            return;
        }
        requestGetUser(code);
    }, [code]);
    const requestGetUser = async (code: string) => {
        try {
            await getKakaoUserAdmin(code);
            navigate('/login');
        } catch (error) {
            console.error("Error: ", error)
        }

    }
    return <>
        <Button onClick={() => navigate('/')} > 홈페이지</Button>
        <Button onClick={() => navigate('/login')} > 로그인 페이지</Button>

    </>
}
export default KakaoLoginCallback;