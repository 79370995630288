import dayjs from "dayjs"
import DateRangePicker from "../../ui/dateRangePicker"
import SalesDailyChart from "./ui/chart";
import SalesReportTableDaily from "./ui/table";
import { getReportSalesDaily } from "../../../../service/report/sales";
import { useEffect, useState } from "react";
import SuperAdminPassword from "../../../../components/SuperAdminPassword";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";

const SalesReportDaily = () => {
    const [salesDailyChartData, setSalesDailyChartData] = useState<Array<ISalesReportData>>([])
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);

    useEffect(() => {
        requestReportSalesDaily(dayjs().subtract(6, 'days').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    }, [])

    const requestReportSalesDaily = (startDate: string, endDate: string) => {
        getReportSalesDaily(startDate, endDate)
            .then((res) => setSalesDailyChartData(res.data))
            .catch(error => console.error('error', error))
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        <DateRangePicker
            startDate={dayjs().subtract(6, 'days')}
            onClick={(startDate: string, endDate: string) => requestReportSalesDaily(startDate, endDate)} />
        <br /><br />
        {salesDailyChartData.length > 0 && <>
            <SalesDailyChart data={salesDailyChartData} />
            <SalesReportTableDaily data={salesDailyChartData} />
        </>}
    </>
}

export default SalesReportDaily