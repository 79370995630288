import { AxiosResponse } from 'axios';
import api from './Api'

export const getMemberById = (id: string): Promise<AxiosResponse<IMember>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/detail/${id}`);
}
export const getMemberByToken = (): Promise<AxiosResponse<IMemberByToken>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/token`);
}
export const getMemberByMobileEndingWith = (mobile: string): Promise<AxiosResponse<Array<ICheckInMember>>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/mobile/${mobile}`);
}

export const getMemberCheckInById = (id: string): Promise<AxiosResponse<ICheckInMember>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/check-in/${id}`);
}
export const getMemberList = (): Promise<AxiosResponse<Array<IMember>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member`)
}
export const getMemberListByRole = (role: 'ROLE_ADMIN' | 'ROLE_YOGA_CENTER_ADMIN' | 'ROLE_FOOD_CENTER_ADMIN' | 'ROLE_TEACHER' | 'ROLE_USER' | 'ROLE_BLACK'): Promise<AxiosResponse<Array<IMember>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/role/${role}`)
}
export const getMemberListForNotification = (): Promise<AxiosResponse<Array<IMemberForNotification>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/notification`)
}
export const updateMemo = (memoPutRequest: IMemoPutRequest) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/member/memo`, memoPutRequest)
}
export const createMember = (member: ICreateMember) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/member`, member);
}
export const deleteMember = (idList: Array<number>): Promise<AxiosResponse<string>> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/member`, { data: { idList: idList } })
};
export const getCompanyMemberList = (companyId: number, searchValue: string, remainingCnt: string, remainingDate: string): Promise<AxiosResponse<Array<IMember>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/company?companyId=${companyId}&search=${searchValue}&remainingCnt=${remainingCnt}&remainingDate=${remainingDate}`)
}

export const updateMemberRoleList = (authRoleNameListRequest: any) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/auth/admin/role`, authRoleNameListRequest)
}

export const getRegisteredMemberInPeriod = (startDate: string, endDate: string): Promise<AxiosResponse<IRegisteredMemberData[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/registered/period/${startDate}/${endDate}`)
}