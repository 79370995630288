import dayjs from "dayjs";
import DateRangePicker from "../../ui/dateRangePicker";
import ReservationReportCenterChart from "./ui/chart";
import ReservationReportCenterTable from "./ui/table";
import { requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByCenter } from "../../../../service/report";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";
import SuperAdminPassword from "../../../../components/SuperAdminPassword";

const ReservationReportCenter = () => {
    const [data, setData] = useState<any[]>([]);
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);
    const startDate = dayjs().subtract(7, 'week')
    const getCheckInStatusToText = (status: string) => {
        switch (status) {
            case 'RESERVATION':
                return '예약'
            case 'CHECK_IN_RESERVATION_CANCELED':
                return '예약 취소'
            case 'CHECK_IN':
                return '출석'
            case 'ABSENT':
                return '결석'
        }
    }
    const requestReportMember = (startDate: string, endDate: string) => {
        requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByCenter(startDate, endDate)
            .then(res => { setData(res.data); })
            .catch(err => { console.log(err); });
    }
    const groupDataByName = (data: any[]) => {
        if (data.length === 0) return [];
        const grouped: { [key: string]: any } = {};
        data.forEach(item => {
            if (!grouped[item.name]) {
                grouped[item.name] = {
                    center: item.name,
                    reservationCount: 0,
                    RESERVATION: 0,
                    CHECK_IN_RESERVATION_CANCELED: 0,
                    CHECK_IN: 0,
                    ABSENT: 0,
                    ABSENTRatio: 0,
                };
            }
        })
        if (!grouped['Total']) {
            grouped['Total'] = {
                center: 'Total',
                reservationCount: data.reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                RESERVATION: null,
                CHECK_IN_RESERVATION_CANCELED: null,
                CHECK_IN: null,
                ABSENT: null,
                ABSENTRatio: null,
            }
        }
        data.forEach(item => {
            switch (item.status) {
                case 'RESERVATION':
                    grouped[item.name].RESERVATION = item.reservationCount || 0;
                    grouped['Total'].RESERVATION += item.reservationCount || 0;
                    break;
                case 'CHECK_IN':
                    grouped[item.name].CHECK_IN = item.reservationCount || 0;
                    grouped['Total'].CHECK_IN += item.reservationCount || 0;
                    break;
                case 'ABSENT':
                    grouped[item.name].ABSENT = item.reservationCount || 0;
                    grouped['Total'].ABSENT += item.reservationCount || 0;
                    break;
                case 'CHECK_IN_RESERVATION_CANCELED':
                    grouped[item.name].CHECK_IN_RESERVATION_CANCELED = item.reservationCount || 0;
                    grouped['Total'].CHECK_IN_RESERVATION_CANCELED += item.reservationCount || 0;
                    break;
                default:
                    break;
            }
        });
        Object.values(grouped).forEach(item => { grouped[item.center].ABSENTRatio = (item.ABSENT / (item.RESERVATION + item.CHECK_IN + item.ABSENT) * 100).toFixed(2) + '%'; })
        return Object.values(grouped);
    }
    useEffect(() => {
        requestReportMember(startDate.format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    }, [])

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        <DateRangePicker
            startDate={startDate}
            onClick={(startDate: string, endDate: string) => requestReportMember(startDate, endDate)} />
        <br /><br />
        <ReservationReportCenterChart value={data.filter((e: any) => e.status != 'CLASS_BEFORE').map(q => ({ count: q.reservationCount, center: q.name, status: getCheckInStatusToText(q.status) }))} />
        <br /><br />
        <ReservationReportCenterTable data={groupDataByName(data.map((q, idx) => ({ ...q, key: idx })))} />
    </>
}
export default ReservationReportCenter